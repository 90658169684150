
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import AWS from 'aws-sdk';
import { getConfigEnv } from '@/utils/helpers';
import {
  IDENTITY_POOL_ID,
  SCHEDULE_BUCKET_NAME,
  SCHEDULE_BUCKET_REGION,
} from '@/lib/constants';
// Assuming accountModule is a Vuex module, ensure it's compatible with TypeScript usage.
import accountModule from '@/store/modules/accountModule';

@Component
export default class FileUploadComponent extends Vue {
  @Prop({ default: '' }) readonly error!: string;
  @Prop({ default: false }) readonly hideUpload!: boolean;
  @Prop({ default: false }) dataInProcess!: boolean;
  @Prop({ default: false }) readonly isSidebar!: boolean;
  @Prop({ default: () => [] }) readonly files!: Array<any>;
  @Prop({ default: 'workTickets' }) readonly basePath!: string;
  @Prop({ default: 'Select your files or images ' }) readonly title!: string;
  @Prop({ default: 'or drag and drop them here' }) readonly subtitle!: string;

  media: Array<any> = [];
  loading: boolean = false;
  isHoverActive: boolean = false;

  isFileUploading = false;


  mounted() {
    if (this.files && this.files.length > 0) {
      this.media = this.files;
    }
    this.emitMedia(this.media);
  }

  checkIfFileIsImage(name: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(name);
  }

  getExtensionName(type: string): string {
    return type.split('.').pop() || type;
  }

  @Emit()
  emitMedia(media: Array<any>) {
    return media;
  }

  async fileChange(event: any) {
    this.media = []
    this.loading = true;
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const url = URL.createObjectURL(file);
      this.media= [{
        url,
        name: file.name,
        size: file.size,
        type: file.type,
        isHoverActive: false,
      }];
      await this.processFileUpload(file);
    }
    this.loading = false;
    this.emitMedia(this.media);
  }

  remove(index: number) {
    this.media.splice(index, 1);
    this.emitMedia(this.media);
  }

  dragover(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer!.dropEffect = 'copy';
    this.isHoverActive = true;
  }

  drop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.isHoverActive = false;
    const files = e.dataTransfer!.files;
    this.createFile(files);
  }

  createFile(files: FileList | null) {
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const url = URL.createObjectURL(file);
      this.media.push({
        url,
        name: file.name,
        size: file.size,
        type: file.type,
        isHoverActive: false,
      });
      this.processFileUpload(file);
    }
  }

  async processFileUpload(file: File) {
    this.isFileUploading = true
    const extension = this.getExtensionName(file.name);
    const operator = getConfigEnv('OPERATOR_LOWERCASED');
    this.$emit('set-saving-details-banner-id', `Uploading "${file.name}"`);

    AWS.config.update({
      region: SCHEDULE_BUCKET_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
      }),
    });

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: SCHEDULE_BUCKET_NAME },
    });

    const unixTimestamp = Math.floor(Date.now() / 1000);

    const key = `${operator}/${
      this.basePath
    }/${accountModule.user.email.toLowerCase()}/${unixTimestamp}_${file.name}`;

    try {
      const uploadResult = await s3
        .upload({
          Key: key,
          Bucket: SCHEDULE_BUCKET_NAME,
          Body: file,
          ACL: 'public-read',
          ContentType: extension === 'pdf' ? 'application/pdf' : file.type,
          ContentDisposition: extension === 'pdf' ? 'inline' : undefined,
        })
        .promise();

      this.media.pop();
      this.media.push({
        url: uploadResult.Location,
        name: file.name,
        size: file.size,
        type: file.type,
        isHoverActive: false,
      });
      console.log('uploaded');
      this.$emit('media',this.media);
    } catch (err) {
      console.error(err, 'There was an error uploading your file.');
    }

    this.isFileUploading = false
  }
}
